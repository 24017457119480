<template>
  <div class="equipment-info-wrapper">
    <van-nav-bar
      title="设备基本信息"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    ></van-nav-bar>
    <div class="equipment-info">
      <template v-for="item in infos">
        <van-field
          :key="item.label"
          :value="item.formatter ? item.formatter(item) : info[item.prop]"
          :label="item.label"
          readonly
          placeholder=""
          input-align="right"
        />
        <div
          v-if="item.margin"
          :key="'margin-' + item.label"
          style="background-color: #ECEEF2;height: 12px"
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
import { getEquipmentInfo } from "@/api/basics/equipment";
export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      info: {},
      infos: [
        {
          label: "设备位号",
          prop: "machineNo"
        },
        {
          label: "设备名称",
          prop: "name"
        },
        {
          label: "所属部门",
          prop: "departmentName"
        },
        {
          label: "所属场所设施",
          prop: "poiName"
        },
        {
          label: "设备类型",
          prop: "typeName"
        },
        {
          label: "设备分类",
          prop: "categoryName"
        },
        {
          label: "设备等级",
          prop: "levelName"
        },
        {
          label: "设备状态",
          prop: "equipmentStatusName"
        },
        {
          label: "是否报废",
          formatter: item => {
            if (item.scrapVo && item.scrapVo.scrapDate) {
              return "是";
            }
            return "否";
          }
        },
        {
          label: "规格型号",
          prop: "model",
          margin: true
        },
        {
          label: "生产日期",
          prop: "produceDate"
        },
        {
          label: "生产厂家",
          prop: "produceManufacturers"
        },
        {
          label: "出厂编号",
          prop: "manufacturesNum"
        },
        {
          label: "设备重量(Kg)",
          prop: "weight"
        },
        {
          label: "使用年限(年)",
          prop: "useYear",
          margin: true
        },
        {
          label: "固定资产编号",
          prop: "fixedAssetsNum"
        },
        {
          label: "安装日期",
          prop: "installDate"
        },
        {
          label: "安装单位",
          prop: "installManufactures"
        },
        {
          label: "安装位置",
          prop: "location"
        },
        {
          label: "启用日期",
          prop: "useDate"
        },
        {
          label: "材质",
          prop: "texture"
        },
        {
          label: "责任人",
          prop: "dutyPersonName"
        }
      ]
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getEquipmentInfo(this.id).then(res => {
        this.info = res;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-info-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-info {
    flex: 1;
    height: 0;
    overflow-y: auto;
  }
}
</style>
